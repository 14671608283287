import { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { useLinkComponent } from '@link';
import { getIllustration, wrap } from '@sitecore/common';
import { Illustrations } from '@sitecore/types/manual/Illustrations';
import { USPItemRendering } from '@sitecore/types/manual/USPItem';
import { Text, Stack, TextLink } from '@sparky';

const IconIllustration: FC<{ illustration: Illustrations }> = params => {
  const Illustration = getIllustration(params.illustration);
  return <Illustration color="brandGradient" size={{ initial: 'medium', md: 'large' }} />;
};

const USPItem: FC<USPItemRendering> = ({ fields, params: { mobileVariant = 'default' } }) => {
  const isCompactOnMobile = mobileVariant === 'compact';
  const Link = useLinkComponent();

  return (
    <Stack
      gap={isCompactOnMobile ? { initial: 8, md: 6 } : 6}
      alignY={isCompactOnMobile ? { initial: 'center', md: 'start' } : 'start'}
      direction={isCompactOnMobile ? { initial: 'row', md: 'column' } : 'column'}>
      {fields.illustration && (
        <Stack.Item>
          <IconIllustration illustration={fields.illustration.value} />
        </Stack.Item>
      )}
      <Stack gap="3">
        <Text weight="bold" size={{ initial: 'BodyM', md: 'BodyL' }}>
          {wrap(fields.title)}
        </Text>
        <Text size={{ initial: 'BodyM', md: 'BodyL' }}>
          {wrap(fields.description, <RichText html={fields.description.value} />)}
        </Text>
        {fields.link?.value?.href && fields.link.value.text && (
          <Link linkType={fields.link.value.linktype} linkValue={fields.link.value}>
            <TextLink emphasis="high" target={fields.link.value.target}>
              {fields.link.value.text}
            </TextLink>
          </Link>
        )}
      </Stack>
    </Stack>
  );
};

export default USPItem;
